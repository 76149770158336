import {
  DropdownItemCheckbox,
  DropdownItemGroupCheckbox
} from '@atlaskit/dropdown-menu'
import React from 'react'
import { CheckBoxWrapper } from '../../styles'
import { IOption } from '../../types'
import OptionControl from './OptionControl'
import OptionLabel from './OptionLabel'

type PropType = {
  selected: any[]
  onOptionClick: (e: any, id: string) => void
  getIconOrColor: (option: IOption) => string
  optionType: 'checkbox' | 'radio'
}

function SelectedOptions({
  selected = [],
  onOptionClick = () => {},
  getIconOrColor = () => '',
  optionType = 'checkbox'
}: PropType) {
  return (
    <DropdownItemGroupCheckbox id=''>
      {selected.map((option) => (
        <CheckBoxWrapper key={option.value} isChecked>
          <OptionControl
            onClick={(e) => onOptionClick(e, option.value)}
            id={option.value}
            isSelected
            optionType={optionType}
          >
            <OptionLabel
              iconOrColor={getIconOrColor(option)}
              label={option.label}
            />
          </OptionControl>
        </CheckBoxWrapper>
      ))}
    </DropdownItemGroupCheckbox>
  )
}

export default SelectedOptions
