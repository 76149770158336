import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import colorToRGB from '../../utils/colorToRGB'

function OptionLabel({ iconOrColor = '', label = '' }) {
  const [iconOrColorData, setICData] = useState<{
    type?: string
    value?: string | any
  }>({})

  useEffect(() => {
    if (iconOrColor) {
      let isUrl = iconOrColor.includes('/')
      setICData({
        type: isUrl ? 'icon' : 'color',
        value: isUrl ? iconOrColor : colorToRGB(iconOrColor)
      })
    }
  }, [iconOrColor])

  if (!Object.keys(iconOrColorData || {}).length)
    return <div className='option-label'>{label}</div>

  return (
    <div>
      {iconOrColorData.type === 'icon' ? (
        <div className='filter-option'>
          <img alt='' src={iconOrColorData.value} />
          <div className='option-label'>{label}</div>
        </div>
      ) : (
        <div
          className='option-lozenge'
          style={{
            background: `rgb(${iconOrColorData.value?.[0]},${iconOrColorData.value?.[1]},${iconOrColorData.value?.[2]},0.07)`,
            color: `rgb(${iconOrColorData.value?.[0]},${iconOrColorData.value?.[1]},${iconOrColorData.value?.[2]},1)`
          }}
        >
          <div className='option-label'>{label}</div>
        </div>
      )}
    </div>
  )
}

export default React.memo(OptionLabel)
