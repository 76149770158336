import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup
} from '@atlaskit/dropdown-menu'
import React from 'react'
import { ThemeType } from '../../types'
import Button from '@atlaskit/button'

type propType = {
  hideClearAll: boolean
  isMulti: boolean
  hideSelectAll: boolean
  clearSelected: () => void
  selectAll: () => void
  theme: ThemeType
}

function Menu({
  hideClearAll = false,
  isMulti = false,
  hideSelectAll = false,
  clearSelected = () => {},
  selectAll = () => {},
  theme
}: propType) {
  const Color = {
    color: theme.color,
    background: theme.background
  }

  if (!hideClearAll && !hideSelectAll)
    return (
      <DropdownMenu trigger='Clear or Select All' triggerType='button'>
        <DropdownItemGroup>
          {!hideClearAll && (
            <DropdownItem
              style={{
                ...Color,
                textAlign: 'left'
              }}
              onClick={clearSelected}
            >
              Clear selected
            </DropdownItem>
          )}
          {!hideSelectAll && (
            <DropdownItem
              style={{
                ...Color,
                textAlign: 'left'
              }}
              onClick={selectAll}
            >
              Select All
            </DropdownItem>
          )}
        </DropdownItemGroup>
      </DropdownMenu>
    )

  if (hideSelectAll) {
    return (
      <Button
        css={{ alignItems: 'center' }}
        onClick={clearSelected}
        className='icon'
      >
        Clear selected
      </Button>
    )
  }

  if (hideClearAll) {
    return (
      <Button onClick={selectAll} css={{}} className='icon'>
        Select All
      </Button>
    )
  }

  return null
}

export default Menu
