import {
  DropdownItemGroupCheckbox,
  DropdownItemCheckbox
} from '@atlaskit/dropdown-menu'
import React from 'react'
import { IOption } from '../../types'
import OptionControl from './OptionControl'
import OptionLabel from './OptionLabel'

type PropType = {
  searchResult: IOption[]
  onOptionClick: (e: any, value: string) => void
  selected: any[]
  optionType: 'checkbox' | 'radio'
}

function SearchResults({
  searchResult = [],
  onOptionClick = () => {},
  selected = [],
  optionType = 'checkbox'
}: PropType) {
  return (
    <DropdownItemGroupCheckbox id=''>
      {searchResult.map((option: IOption) => (
        <OptionControl
          onClick={(e: any) => onOptionClick(e, option.value)}
          key={option.value}
          id={option.value}
          isSelected={
            selected.findIndex((opt) => opt.value === option.value) !== -1
          }
          optionType={optionType}
        >
          <OptionLabel
            iconOrColor={option.iconUrl || option.colorName}
            label={option.label}
          />
        </OptionControl>
      ))}
    </DropdownItemGroupCheckbox>
  )
}

export default SearchResults
