import React, { useRef } from 'react'
import { DragObjectWithType, useDrag, useDrop } from 'react-dnd'
import { MovableChip } from '../styles'
import { Theme } from '@atlaskit/button'
import { ThemeType } from '../types'

const ItemTypes = {
  CARD: 'card'
}

type PropType = {
  id: string
  text: string
  index: number
  moveCard: (dargIndex: number, hoverIndex: number) => void
  theme: ThemeType
}

export const Card = ({ id, text, index, moveCard, theme }: PropType) => {
  const ref = useRef<any>(null)
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    hover(
      item: DragObjectWithType & { index: number },
      monitor: { getClientOffset: () => any }
    ) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    }
  })
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.CARD, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))
  return (
    <MovableChip
      color={theme.dropdownBackground}
      background={theme.color}
      innerRef={ref}
      style={{ opacity }}
    >
      {text}
    </MovableChip>
  )
}
