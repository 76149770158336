import React, { ChangeEvent } from 'react'
import { StyledSearchContainer, SearchBox } from '../../styles'
import { ThemeType } from '../../types'
import { ReactComponent as SearchIcon } from './searchIcon.svg'
import { ReactComponent as ClearIcon } from './cancelIcon.svg'

type PropType = {
  onSearch: (e: ChangeEvent<HTMLInputElement>) => Promise<void> | void
  searchValue: string
  searchPlaceholder: string
  onSearchClear: () => void
  theme: ThemeType
}

function SearchBar({
  onSearch = () => {},
  searchValue = '',
  searchPlaceholder = '',
  onSearchClear = () => {},
  theme
}: PropType) {
  return (
    <StyledSearchContainer color={theme.color}>
      <SearchBox>
        <div className='searchBox'>
          <input
            onChange={onSearch}
            value={searchValue}
            className='search-input'
            placeholder={searchPlaceholder}
          />
          <div>
            <SearchIcon className='searchIcon' />
          </div>
          {searchValue ? (
            <div onClick={onSearchClear}>
              <ClearIcon className='clearIcon' />
            </div>
          ) : null}
        </div>
      </SearchBox>
    </StyledSearchContainer>
  )
}

export default SearchBar
