import React from 'react'
import { StyledSortContainer } from '../../styles'
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Card } from '../Card'
import { ThemeType } from '../../types'
import { Theme } from '@atlaskit/button'

type PropType = {
  moveCard: (dragIndex: any, hoverIndex: any) => void
  setIsSortShown: (flag: boolean) => void
  selected: any[]
  theme: ThemeType
}
function SortableOptions({
  setIsSortShown = () => {},
  selected = [],
  moveCard = () => {},
  theme
}: PropType) {
  return (
    <StyledSortContainer
      color={theme.color}
      background={theme.dropdownBackground}
    >
      <div className='header'>
        <div onClick={() => setIsSortShown(false)} className='close-icon'>
          <EditorCloseIcon label='' />
        </div>
      </div>
      <div className='title' style={{ color: theme.color }}>
        Rearrange chips for column order
      </div>
      <div className='container'>
        {selected.length > 0 ? (
          <DndProvider backend={HTML5Backend}>
            {selected.map((item, index) => (
              <Card
                key={item.value}
                index={index}
                id={item.value}
                text={item.label}
                moveCard={moveCard}
                theme={theme}
              />
            ))}
          </DndProvider>
        ) : (
          <div className='empty-message'>Select some options to sort..</div>
        )}
      </div>
    </StyledSortContainer>
  )
}

export default SortableOptions
