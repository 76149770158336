import React, { ChangeEvent, useCallback, useState, useRef } from 'react'
import DropdownMenu from '@atlaskit/dropdown-menu'
import update from 'immutability-helper'
import LoadingSkeleton from './components/LoadingSkeleton'
import Button from '@atlaskit/button'
import { ReactComponent as SortIcon } from './icons/sort.svg'
import Tooltip from '@atlaskit/tooltip'
import SortableOptions from './components/option/SortableOptions'
import SearchBar from './components/controls/SearchBar'
import Menu from './components/controls/Menu'
import SearchResults from './components/option/SearchResults'
import OptionList from './components/option/OptionList'
import SelectedOptions from './components/option/SelectedOptions'
import {
  handleMenuToggle,
  handleOptionClick,
  hasOptions,
  searchHandler
} from './functions'
import { IOption, SelectPropTypes, ThemeType } from './types'
import {
  HintText,
  MenuContainer,
  OptionsWrapper,
  StyledDropDown,
  StyledSelectWrapper,
  StyledTitle
} from './styles'

const initialTheme: ThemeType = {
  color: '#2a3a55f9',
  background: '#091e420a',
  dropdownBackground: 'white',
  loaderColor: '#DFE1E6'
}

const Select = ({
  label = '',
  options = [],
  onChange = () => {},
  isSelectedAllByDefault = false,
  isLoading = false,
  onSearch = null,
  selected = [],
  isMulti = true,
  isSortable = false,
  hideSelectAll = false,
  hideClearAll = false,
  isPaginated = false,
  id = '',
  onNextPage = null,
  isPageFinished = false,
  isPageNeverFinish = false,
  optionType = 'checkbox',
  theme = initialTheme
}: SelectPropTypes) => {
  const searchValueRef = useRef('')
  const [searchValue, setSearchValue] = useState('')
  const [searchResult, setSearchResult] = useState<IOption[]>([])
  const [isSortShown, setIsSortShown] = useState(false)
  const pageIndex = useRef(0)
  const isPageFinishedRef = useRef(false)
  isPageFinishedRef.current = isPageFinished

  hideSelectAll = optionType === 'radio' || hideSelectAll

  const [hint] = useState(
    isPageNeverFinish
      ? "Can't find what you are looking for? Try searching.."
      : ''
  )

  function onScroll(this: HTMLDivElement) {
    if (
      this.scrollHeight - this.scrollTop - this.clientHeight < 1 &&
      !isPageFinishedRef.current &&
      !searchValueRef.current
    ) {
      const pIndex = (pageIndex.current = pageIndex.current + 1)
      if (pIndex) onNextPage(pIndex)
    }
  }

  const groups: string[] = Array.from(
    new Set(
      options
        .filter((opt: IOption) => opt.group)
        .map((opt: IOption) => opt.group)
    ) || []
  )

  const optionsArray = groups.filter((group) =>
    hasOptions(options, selected, group)
  )

  const onOptionClick = useCallback(
    (e, id) => {
      handleOptionClick(
        e,
        id,
        selected,
        options,
        searchResult,
        onChange,
        isMulti,
        optionType
      )
    },
    [selected]
  )

  const getName = () => {
    if (selected.length === 0) {
      return isSelectedAllByDefault ? `${label}: All` : label
    }
    let selectedString = ''
    selected.forEach((option) => (selectedString += `${option.label}, `))
    return `${selectedString.substr(0, 15)}...`
  }

  const clearSelected = () => {
    onChange([])
  }

  const onSearchHandler = async (e: ChangeEvent<HTMLInputElement>) => {
    const shouldCallOnSerachCall: boolean =
      onSearch && (!isPageFinished || isPageNeverFinish)
    searchHandler(
      e,
      searchValueRef,
      setSearchValue,
      (shouldCallOnSerachCall && onSearch) || null,
      setSearchResult,
      options
    )
  }

  const selectAll = () => {
    onChange(options)
  }

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = selected[dragIndex]
      onChange(
        update(selected, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard]
          ]
        })
      )
    },
    [selected]
  )

  const onMenuToggle = (e: any) =>
    handleMenuToggle(e, isPaginated, onNextPage, id, onScroll)

  const getIconOrColor = React.useCallback(
    (option) => {
      if (option.iconUrl) return option.iconUrl
      if (option.colorName) return option.colorName

      const optionObj: IOption | undefined = options.find(
        (opt) => opt.value === option.value
      )
      if (optionObj) {
        if (optionObj.iconUrl) return optionObj.iconUrl
        if (optionObj.colorName) return optionObj.colorName
        return ''
      }
      return ''
    },
    [options]
  )

  return (
    <StyledSelectWrapper background={theme.background} color={theme.color}>
      <DropdownMenu
        onOpenChange={onMenuToggle}
        testId={id}
        trigger={getName()}
        triggerType='button'
      >
        {options.length === 0 && !isLoading ? (
          <div className='no-items-found'>No item found!</div>
        ) : isSortShown ? (
          <SortableOptions
            selected={selected}
            moveCard={moveCard}
            setIsSortShown={setIsSortShown}
            theme={theme}
          />
        ) : (
          <StyledDropDown
            background={theme.dropdownBackground}
            color={theme.color}
          >
            <SearchBar
              onSearch={onSearchHandler}
              searchValue={searchValue}
              searchPlaceholder={`Find ${label}...`}
              onSearchClear={() => {
                setSearchValue('')
                searchValueRef.current = ''
              }}
              theme={theme}
            />

            <MenuContainer>
              {(!hideSelectAll || !hideClearAll) && (
                <Menu
                  hideClearAll={hideClearAll}
                  hideSelectAll={hideSelectAll}
                  isMulti={isMulti}
                  clearSelected={clearSelected}
                  selectAll={selectAll}
                  theme={theme}
                />
              )}
              {isSortable && (
                <Tooltip
                  content='Rearrange selected values'
                  hideTooltipOnMouseDown
                  hideTooltipOnClick
                  position='top'
                >
                  <Button
                    className='icon'
                    iconBefore={<SortIcon width={17} />}
                    onClick={() => setIsSortShown(true)}
                    css={{}}
                  />
                </Tooltip>
              )}
            </MenuContainer>

            <OptionsWrapper height={hint ? '240px' : '258px'} id={id}>
              {searchValue ? (
                <div>
                  {searchResult.length > 0 ? (
                    <SearchResults
                      searchResult={searchResult}
                      onOptionClick={onOptionClick}
                      selected={selected}
                      optionType={optionType}
                    />
                  ) : (
                    <StyledTitle
                      style={{ padding: '10px 0 5px 0', textAlign: 'center' }}
                    >
                      No Matches
                    </StyledTitle>
                  )}
                </div>
              ) : (
                <div>
                  {selected.length > 0 && (
                    <SelectedOptions
                      selected={selected}
                      onOptionClick={onOptionClick}
                      getIconOrColor={getIconOrColor}
                      optionType={optionType}
                    />
                  )}

                  {optionsArray.length ? (
                    optionsArray.map((group) => (
                      <OptionList
                        group={group}
                        key={group}
                        options={options}
                        selected={selected}
                        onOptionClick={onOptionClick}
                        optionType={optionType}
                        theme={theme}
                      />
                    ))
                  ) : (
                    <OptionList
                      options={options}
                      selected={selected}
                      onOptionClick={onOptionClick}
                      optionType={optionType}
                      theme={theme}
                    />
                  )}
                </div>
              )}
              {isLoading && (
                <React.Fragment>
                  <LoadingSkeleton theme={theme} />
                  <LoadingSkeleton theme={theme} />
                  <LoadingSkeleton theme={theme} />
                  <LoadingSkeleton theme={theme} />
                </React.Fragment>
              )}
            </OptionsWrapper>
          </StyledDropDown>
        )}

        {hint && (
          <HintText
            style={{ fontSize: isPageNeverFinish ? '10px' : 'inherit' }}
            className='hint'
          >
            {hint}
          </HintText>
        )}
      </DropdownMenu>
    </StyledSelectWrapper>
  )
}

export default React.memo(Select)
