import styled from 'styled-components'

export const StyledSearchContainer = styled.div`
  padding: 5px 7px;
  display: flex;
  color: ${(props) => props.color};
  align-items: center;
  justify-content: space-around;
  background: inherit;

  .search-input {
    border: none;
    background: inherit;
    color: ${(props) => props.color};
    height: 24px;
    padding: 0 28px;

    ::placeholder {
      font-size: 13px;
    }
  }
`

export const StyledTitle = styled.div`
  padding: 5px 10px;
  font-weight: 500;
  cursor: ${(props: { isButton?: boolean }) =>
    props.isButton ? 'pointer' : 'auto'};
  text-transform: capitalize;
  font-size: 13px;
  opacity: 0.8;

  :hover {
    text-decoration: ${(props: { isButton?: boolean }) =>
      props.isButton ? 'underline' : 'none'};
  }
`

export const StyledSortContainer = styled.div<{ background: string }>`
  padding: 0 5px 5px 5px;
  font-size: 14px;
  color: ${(props) => props.color};
  background: ${(props) => props.background};

  .header {
    display: flex;
    justify-content: flex-end;
  }
  .close-icon {
    cursor: pointer;
  }
  .title {
    color: black;
    opacity: 0.8;
    padding: 0 8px;
  }
  .container {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid lightgray;
    border-radius: 3px;
    margin: 8px;
    align-content: flex-start;
    min-height: 100px;
    padding: 4px;
    overflow: auto;
    max-height: 15.3rem;
  }
  .empty-message {
    padding: 15px;
    opacity: 0.6;
    font-size: 13px;
  }
`

export const MovableChip = styled.div<{ background: string }>`
  cursor: move;
  margin: 4px;
  padding: 3px 6px;
  font-size: 13px;
  opacity: 1;
  color: ${(props) => props.color};
  background: ${(props) => props.background};
`

export const StyledSelectWrapper = styled.div<any>`
  div[data-role='droplistContent'] {
    padding: 0;
    overflow: unset;
  }

  div > button {
    background: ${(props) => props.background};
    color: ${(props) => props.color} !important;
    fill: ${(props) => props.color} !important;
    font-weight: 400;
    align-items: center;

    :hover {
      background: ${(props) => props.background};
      color: ${(props) => props.color} !important;
      fill: ${(props) => props.color} !important;
      font-weight: 400;
    }
  }

  .no-items-found {
    padding: 25px;
    opacity: 0.8;
  }
`

export const CheckBoxWrapper = styled.div<any>`
  svg {
    color: ${(props: any) =>
      props?.isChecked ? '#0052CC' : '#DFE1E6'}!important;
  }
`

export const StyledDropDown = styled.div<any>`
  background: ${(props) => props.background};
  border: 1px solid #dedede42;
  .filter-option {
    display: flex;
    align-items: center;
    img {
      width: 16px;
      margin-right: 5px;
    }
  }

  span[role='checkbox'],
  span[role='radio'],
  span[role='menuitemcheckbox'],
  span[role='menuitemradio'] {
    background: ${(props) => props.background};
    color: ${(props) => props.color};

    :hover {
      background: ${(props) => props.background};
      color: ${(props) => props.color};
    }
  }
`

export const HintText = styled.div`
  height: 17px;
  border-top: 1px solid #d3d3d3;
  font-size: 12px;
  text-transform: none;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const MenuContainer = styled.div`
  font-size: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 0 5px;

  button[aria-haspopup='true'] {
    padding: 0 4px !important;
    height: 22px !important;
    display: flex !important;
    align-items: center !important;
  }
  button {
    height: 22px;
  }
  button.icon {
    padding: 7px;
    margin-left: 7px;
  }
`

export const SkeletonWrapper = styled.div`
  display: flex;
  padding: 6px 12px;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;

  /* The loading Class */

  position: relative;

  /* The moving element */
  ::after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background: ${(props) => `linear-gradient(
      90deg,
      transparent,
      ${props.theme.dropdownBackground},
      transparent
    )`};
    max-width: 100%;
    overflow: hidden;

    /* Adding animation */
    animation: loading 0.8s infinite;
    top: 0;
  }

  /* Loading Animation */
  @keyframes loading {
    100% {
      transform: translateX(100%);
    }
  }

  .checkbox-skeleton {
    width: 12px;
    height: 12px;
    margin: 6px;
    background: ${(props) => props.theme.loaderColor};
    margin-right: 11px;
    border-radius: 2px;
  }
  .text-skeleton {
    width: 80%;
    height: 12px;
    background: ${(props) => props.theme.loaderColor};
    border-radius: 2px;
  }
`

export const OptionsWrapper = styled.div`
  overflow-y: auto;
  max-height: ${(props: { height: string }) => props.height || '258px'};
  font-size: 14px;

  .option-label {
    opacity: 0.9;
  }
`

/* Search Box styled component */
export const SearchBox = styled.div`
  .searchBox {
    position: relative;
    border-bottom: 1px solid #a7a7a754;
    margin-bottom: 3px;
  }

  input:focus {
    outline: #2e87fc solid 2px;
    border-radius: 2px;
  }

  .searchIcon {
    position: absolute;
    left: 5px;
    top: 6px;
  }
  .clearIcon {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    fill: #b7b7b7;
  }
  .clearIcon:hover {
    fill: #ff8b8b;
  }
`
