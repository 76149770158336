import { uniqBy } from 'lodash'
import { IOption } from './types'

export const handleOptionClick = (
  e: EventTarget | any,
  id: string,
  selected: any[],
  options: IOption[],
  searchResult: IOption[],
  onChange: (options: IOption[]) => void = () => {},
  isMulti: boolean,
  optionType?: 'checkbox' | 'radio'
) => {
  e.persist()
  let selectedOptions = selected
  const clickedItem =
    options.find((option) => option.value === id) ||
    searchResult.find((option) => option.value === id) ||
    selectedOptions.find((option) => option.value === id)
  if (!clickedItem) return
  // unselecting
  if (selectedOptions.findIndex((option) => option.value === id) !== -1) {
    selectedOptions = selectedOptions.filter((option) => option.value !== id)

    optionType === 'radio'
      ? onChange(selectedOptions.slice(-1))
      : onChange(selectedOptions)
    return
  }
  // selecting

  if (isMulti) {
    selectedOptions.push(clickedItem)
    optionType === 'radio'
      ? onChange(selectedOptions.slice(-1))
      : onChange(selectedOptions)

    return
  }
  selectedOptions = [clickedItem]
  optionType === 'radio'
    ? onChange(selectedOptions.slice(-1))
    : onChange(selectedOptions)
}

export const searchHandler = async (
  e: Event | any,
  searchValueRef: any = {},
  setSearchValue: (searchValue: string) => void = () => {},
  onSearch?: ((serachValue: string) => void | PromiseLike<IOption[]>) | null,
  setSearchResult: (options: IOption[]) => void = () => {},
  options: IOption[] = []
) => {
  const value = e.target.value
  searchValueRef.current = value
  setSearchValue(value)

  // if (onSearch && (!isPageFinished || isPageNeverFinish)) {

  if (onSearch) {
    const results = await onSearch(value)
    setSearchResult(results || [])
    return
  }

  const invalid = /[°"§%()\[\]{}=\\?´`'<>|,;.]+/g
  const filteredValue = value ? value.replace(invalid, '') : ''

  const filteredOptions = options.filter((option) =>
    option.label.match(new RegExp(filteredValue, 'i'))
  )
  setSearchResult(uniqBy(filteredOptions, 'value'))
}

export const handleMenuToggle = (
  e: any,
  isPaginated: boolean,
  onNextPage?: ((pageIndex: number) => void) | null,
  id: string = '',
  onScroll: () => void = () => {}
) => {
  if (isPaginated) {
    setTimeout(() => {
      const menuElement = document.getElementById(id)
      if (e.isOpen) {
        if (onNextPage && menuElement) {
          menuElement.addEventListener('scroll', onScroll)
        }
      }
    }, 100)
  }
}

export const hasOptions = (
  options: IOption[] = [],
  selected: any[] = [],
  group: string
) => {
  if (
    options
      .filter(
        (option) =>
          selected.findIndex((opt) => opt.value === option.value) === -1
      )
      .find((option: IOption) =>
        option.group ? option.group === group : false
      )
  ) {
    return true
  }
  return false
}
