import React, {
  MouseEventHandler,
  PropsWithChildren,
  ReactChildren
} from 'react'
import {
  DropdownItemRadio,
  DropdownItemCheckbox
} from '@atlaskit/dropdown-menu'

type PropsType = {
  onClick: MouseEventHandler<HTMLElement>
  id: string
  isSelected: boolean
  children: any
  optionType: 'checkbox' | 'radio'
}

function OptionControl(props: PropsType) {
  if (props.optionType === 'radio') return <DropdownItemRadio {...props} />

  return <DropdownItemCheckbox {...props} />
}

export default OptionControl
