import React from 'react'
import { SkeletonWrapper } from '../styles'
import { ThemeType } from '../types'

function LoadingSkeleton({ theme }: { theme: ThemeType }) {
  return (
    <SkeletonWrapper theme={theme}>
      <div className='checkbox-skeleton '></div>
      <div className='text-skeleton'></div>
    </SkeletonWrapper>
  )
}

export default LoadingSkeleton
