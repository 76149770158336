import {
  DropdownItemCheckbox,
  DropdownItemGroupCheckbox
} from '@atlaskit/dropdown-menu'
import React, { CSSProperties } from 'react'
import { CheckBoxWrapper, StyledTitle } from '../../styles'
import { IOption, ThemeType } from '../../types'
import OptionControl from './OptionControl'
import OptionLabel from './OptionLabel'

type PropType = {
  onOptionClick: (e: any, id: string) => void
  group?: string
  options: IOption[]
  selected: any[]
  optionType?: 'checkbox' | 'radio'
  theme: ThemeType
}

function OptionList({
  group = null,
  options = [],
  selected = [],
  onOptionClick = () => {},
  optionType = 'checkbox',
  theme
}: PropType) {
  return (
    <DropdownItemGroupCheckbox id=''>
      <StyledTitle style={{ color: theme.color }}>{group}</StyledTitle>

      {options
        .filter((option) =>
          group
            ? option.group === group &&
              selected.findIndex((opt) => opt.value === option.value) === -1
            : selected.findIndex((opt) => opt.value === option.value) === -1
        )
        .map((option) => (
          <div key={option.value}>
            <CheckBoxWrapper>
              <OptionControl
                onClick={(e: any) => onOptionClick(e, option.value)}
                id={option.value}
                isSelected={
                  selected.findIndex((opt) => opt.value === option.value) !== -1
                }
                optionType={optionType}
              >
                <OptionLabel
                  iconOrColor={option.iconUrl || option.colorName}
                  label={option.label}
                />
              </OptionControl>
            </CheckBoxWrapper>
          </div>
        ))}
    </DropdownItemGroupCheckbox>
  )
}

export default OptionList
